.App {
  text-align: center;
}

.solenoid {
  font-size: 1.5em;
  font-weight: bold;
}

.solenoid-icon {
  transform: scale(2);
}
